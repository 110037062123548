<template>
    <b-container fluid>
        <b-card no-body class="p-1 mb-2">
            <b-row>
                <b-col cols="12" class="text-right">
                    <b-button variant="primary" @click="load">
                        <b-icon-arrow-repeat font-scale="1.2"/>
                    </b-button>
                </b-col>
            </b-row>
        </b-card>
        <b-table
            hover
            responsive
            head-variant="dark"
            :items="items"
            :fields="fields"
        >
            <template #cell(actions)="row">
                <b-button
                    @click="toPlannedSanitaryInspection(row.item)"
                    size="sm"
                    variant="info"
                >
                    <b-icon-eye-fill/>
                </b-button>
            </template>
        </b-table>
        <b-row>
            <b-col md="6">
                <span>
                    Показано с {{ meta.current_page * meta.per_page - meta.per_page + 1 }}
                    по {{ meta.current_page * meta.per_page - meta.per_page + items.length }}
                    из {{ meta.total_rows }} записей
                </span>
            </b-col>
            <b-col md="6">
                <b-pagination
                    v-model="meta.current_page"
                    :total-rows="meta.total_rows"
                    :per-page="meta.per_page"
                    @input="load"
                    class="justify-content-end"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import {LONG_TERM_SANITARY_INSPECTIONS_ENDPOINT} from '@utils/endpoints';

import moment from 'moment';

export default {
    name: 'Index',
    data() {
        return {
            fields: [
                {
                    key: 'planned_sanitary_inspection',
                    label: 'Наименование проверки',
                    formatter(value) {
                        return value ? value.name : null;
                    }
                },
                {
                    key: 'name',
                    label: 'Наименование показателя',
                },
                {
                    key: 'department',
                    label: 'Подразделение',
                    formatter(value) {
                        return value ? value.name : null;
                    }
                },
                {
                    key: 'planned_sanitary_insepction_started_at',
                    label: 'Дата проведения проверки',
                    formatter(value) {
                        return value ? moment(value).format('DD.MM.YYYY') : null;
                    }
                },
                {
                    key: 'created_at',
                    label: 'Дата создания',
                    formatter(value) {
                        return value ? moment(value).format('DD.MM.YYYY') : null;
                    }
                },
                {
                    key: 'actions',
                    label: 'Действия',
                }
            ],
            items: [],
            meta: {
                current_page: 1,
                total_rows: 0,
                per_page: 25,
            },
            moment,
        }
    },
    methods: {
        load() {
            const params = {
                page: this.meta.current_page,
            };

            this.$http.get(LONG_TERM_SANITARY_INSPECTIONS_ENDPOINT, {params})
                .then((response) => {
                    this.items = response.data.data.data;

                    this.meta = {
                        total_rows: response.data.data.total,
                        current_page: response.data.data.current_page,
                        per_page: response.data.data.per_page,
                    }
                });
        },
        toPlannedSanitaryInspection(item) {
            if (item.planned_sanitary_inspection) {
                this.$router.push({
                    name: 'PlannedSanitaryInspectionShow',
                    params: {
                        id: item.planned_sanitary_inspection.id,
                    }
                });
            }
        }
    },
    mounted() {
        this.load();
    },
}
</script>
